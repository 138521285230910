<template>
  <div class="card card-custom">
    <div class="card-body">
      <associate-contract-wizard :pp-id="ppId" @submitted="goToDetail"></associate-contract-wizard>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AssociateContractWizard from "@/view/components/wizards/AssociateContractWizard.vue";

export default {
  components: {
    AssociateContractWizard,
  },

  computed: {
    ppId() {
      return this.$route.params.ppId ? Number(this.$route.params.ppId) : null;
    },
  },

  mounted() {
    let breadcrumb = [{ title: this.$t("Associate contract") }];
    if (this.ppId) {
      breadcrumb = [
        {
          title: `${this.$t("Physical person")} - ${this.$t("Details")}`,
          route: { name: "detail-physical-person", params: { userID: this.ppId } },
        },
        ...breadcrumb,
      ];
    }
    this.$store.dispatch(SET_BREADCRUMB, breadcrumb);
  },

  methods: {
    goToDetail(e) {
      this.$router.push(e);
    },
  },
};
</script>
