var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('associate-contract-wizard', {
    attrs: {
      "pp-id": _vm.ppId
    },
    on: {
      "submitted": _vm.goToDetail
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }